import React, { useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { useNavigate } from "react-router-dom";
function Orderform() {
  const navigate = useNavigate(); // Hook to redirect after form submission

  let authToken =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NzJhMTRhMjFmZGU4NTM3YWI3OGQxMDgiLCJzZXNzaW9uIjoiNjcyYTE0YTIxZmRlODUzN2FiNzhkMTBhIiwibmFtZSI6IlNhdnlhIEpld2VscyIsImVtYWlsIjoiaW5mb0BzYXZ5YWpld2Vsc2J1c2luZXNzLmNvbSIsInVzZXJUeXBlIjoidXNlciIsInVzZXJJZCI6IjY3MmExNGEyMWZkZTg1MzdhYjc4ZDEwOCIsImlhdCI6MTczMDgxMTA0MiwiZXhwIjoxODg4NTk5MDQyfQ.dhpu9NtuPtCHY0QGMPIBR90oWJRCPTqSX1ppDRppQr1sH6VcdW_TA39gW1oUusIgg-ZlfTyTErathbrbmjE6SdXclvs50KV9Zk9OJlnUDMS-ki1DFfSrqqlamVECCt3prPTsVQDBnimrmLXKwGw6ik8qm2CuVmH0lP_7ZzuMj1Iw5we7x96VKOVhzjJV5ZakJf4lyiP7bIoJAqUdoXD2H-XGQ1VMk5UgCzuezqrw1pAcYZ3N_SPBLyOSGj5Z9YeSPIhKaurXC_GjTUSeECOjEXJfH2pmyAx-M5_xfrmWQAFD9KaQeE65WQeKkcyDmvH-1Zi2r9B0jMjokJDJAjqWuw";
  const [name, setname] = useState("");
  const [firmname, setfirmname] = useState("");
  const [email, setemail] = useState("");
  const [mobile, setmobile] = useState("");
  const [product, setproduct] = useState([]);
  const [productother, setotherproduct] = useState();
  const [weight, setweight] = useState("");
  const [materialtype, setmaterialtype] = useState([]);
  const [purity, setpurity] = useState("");
  const [quantity, setquantity] = useState("");
  const [productphoto, setproductphoto] = useState("");
  const [query, setquery] = useState("");

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleProductChange = (event) => {
    debugger
    const { checked, value } = event.target;
    
    setproduct((prev) => {
      if (checked) {
        return [...prev, value];
      } else if (isChecked) {
        return [...prev, productother];
      }
      else {
        return prev.filter((item) => item !== value);
      }
    });
  };
  const handleOtherProductChange = (value) => {
    debugger    
    setotherproduct(value)
    setproduct((prev) => {            
        return [...prev, productother];
    });
  };

  const handleMaterialChange = (event) => {
    const { checked, value } = event.target;
    setmaterialtype((prev) => {
      if (checked) {
        return [...prev, value];
      } else {
        return prev.filter((item) => item !== value);
      }
    });
  };
  const handleFile = (event) => {

    const formData = new FormData();
    formData.append("Product_Photo", event.target.files[0]);

    fetch("https://api.dialerp.com/api/UploadData/UploadCommonFilesToAzure", {
      method: "POST",
      headers: { HttpHeaders: "multipart/form-data" },
      body: formData,
    })
      .then((result) => {
        result.json().then((resp) => {

          if (resp.hasErrors === false && resp?.response !== null) {
            console.log(resp, "imageUrl");
            setproductphoto(resp?.response[0]["imageUrl"]); // Store the image URL
            alert("Product photo upload successfully!!");
          } else {
            alert(resp?.errors);
          }
        });
      })
      .catch((error) => {

        alert("Error uploading the image. Please try again.");
        console.error("Error uploading file:", error);
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    debugger;
    // Basic validation
    if (
      !name ||
      !firmname ||
      !email ||
      !mobile ||
      !product.length ||
      !weight ||
      !materialtype.length ||
      !purity ||
      !quantity ||
      !productphoto
    ) {
      alert("Please fill in all required fields.");
      // setError();
      return;
    }
    if (!emailRegex.test(email)) {
      alert("Invalid email address.");
      return;
    }

    const ContactPara = {
      name: name,
      firmname: firmname,
      email: email,
      mobile: Number(mobile),
      product: product.join("/ "), // Combine product types with commas
      weight: weight,
      materialtype: materialtype.join("/ "), // Combine material types with commas
      purity: purity,
      quantity: quantity,
      productphoto: productphoto, // Assuming this is the file path or base64 string
      query: query,
      domain: "savyajewelsbusiness.com",
    };

    const headers = {
      "Content-Type": "application/json",
      authorization: authToken,
    };
    try {
      //"https://crmleads.erpthemes.com/save-result",
      const response = await fetch(
        "https://projects.erpthemes.com/api/dynamic/addRecordsDynamic?tempID=43&tempName=savya_order_form",
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(ContactPara),
        }
      );
      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      const result = await response.json();
      if (result && result.resp) {
        if (result.resp?.status) {
          alert(result.resp?.message);
          window.scrollTo(0, 0);
          navigate("/"); // Redirect to the base URL (home page)
          //window.location.reload();
        } else {
          alert(result.resp?.message);
        }
      }
    } catch (error) {
      console.error("Error during fetch:", error.message);
    }
  };
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
  };

  return (
    <>
      <div className="innerpage">
        <Header />
        <div className="appie-page-title-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="appie-page-title-item">
                  <h3 className="title">Order Form</h3>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section className="contact-section ordersection">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6 offset-lg-3">
                <div className="contact-form orderform">
                  <h5> Customer Information</h5>

                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-12">
                        <label>
                          Name<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={name}
                          onChange={(e) => setname(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <label>
                          Firm Name<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={firmname}
                          onChange={(e) => setfirmname(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <label>
                          Your Email<span className="red">*</span>
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <label>
                          Mobile<span className="red">*</span>
                        </label>
                        <input
                          type="tel"
                          className="form-control"
                          value={mobile}
                          onChange={(e) => setmobile(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <h5>Product Details</h5>
                      </div>

                      <div className="col-md-12 mb-4">
                        <label>
                          Product<span className="red">*</span>
                        </label>
                        <div className="condition-check">
                          <input
                            id="ring"
                            type="checkbox"
                            value="Ring"
                            onChange={handleProductChange}
                          />
                          <label htmlFor="ring">Ring</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="necklace"
                            type="checkbox"
                            value="Necklace"
                            onChange={handleProductChange}
                          />
                          <label htmlFor="necklace">Necklace</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="bracelet"
                            type="checkbox"
                            value="Bracelet"
                            onChange={handleProductChange}
                          />
                          <label htmlFor="bracelet">Bracelet</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="earrings"
                            type="checkbox"
                            value="Earrings"
                            onChange={handleProductChange}
                          />
                          <label htmlFor="earrings">Earrings</label>
                        </div>

                        <div className="condition-check">
                          <input
                            id="other"
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}

                          />
                          <label htmlFor="other">Other</label>
                        </div>



                      </div>
                      {isChecked && (
                        <div className="col-md-12">
                          <label>
                            Enter Product Name<span className="red">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={productother}
                            onChange={(e)=>handleOtherProductChange(e.target.value)}
                          />
                        </div>
                      )}


                      <div className="col-md-12">
                        <label>
                          Weight (in grams)<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={weight}
                          onChange={(e) => setweight(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 mb-4">
                        <label>
                          Material Type<span className="red">*</span>
                        </label>
                        <div className="condition-check">
                          <input
                            id="gold"
                            type="checkbox"
                            value="Gold Jewellery"
                            onChange={handleMaterialChange}
                          />
                          <label htmlFor="gold">Gold Jewellery</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="silver"
                            type="checkbox"
                            value="Silver Jewellery"
                            onChange={handleMaterialChange}
                          />
                          <label htmlFor="silver">Silver Jewellery</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="diamond"
                            type="checkbox"
                            value="Diamond Jewellery"
                            onChange={handleMaterialChange}
                          />
                          <label htmlFor="diamond">Diamond Jewellery</label>
                        </div>
                        <div className="condition-check">
                          <input
                            id="platinum"
                            type="checkbox"
                            value="Platinum Jewellery"
                            onChange={handleMaterialChange}
                          />
                          <label htmlFor="platinum">Platinum Jewellery</label>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <label>
                          Purity (e.g., 22K, 18K for Gold)
                          <span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={purity}
                          onChange={(e) => setpurity(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12">
                        <label>
                          Quantity<span className="red">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={quantity}
                          onChange={(e) => setquantity(e.target.value)}
                        />
                      </div>

                      <div className="col-md-12 mb-4">
                        <label>
                          Product Photo<span className="red">*</span>
                        </label>
                        <input
                          type="file"
                          className="form-control"
                          onChange={handleFile}
                        />
                      </div>

                      <div className="col-md-12">
                        <label>Special Instructions</label>
                        <textarea
                          className="form-control"
                          rows="3"
                          value={query}
                          onChange={(e) => setquery(e.target.value)}
                        ></textarea>
                      </div>

                      <div className="col-md-12">
                        <button className="btn btn-theme" type="submit">
                          Send Message
                        </button>
                      </div>
                    </div>
                  </form>
                  {error && <p className="text-danger">{error}</p>}
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </>
  );
}

export default Orderform;
